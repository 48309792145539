import { Component, OnInit } from '@angular/core';
import { MessagesService } from '../services/messages.service';
import { Message } from '../types/message';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  private messages: Message[] = [];

  constructor(private messagesService: MessagesService) { }

  ngOnInit(): void {
    this.messagesService.subscribe((message: Message) => {
      this.messages.push(message);
    })
  }

  getMessages(): Message[] {
    return this.messages;
  }

  display(message: Message) {
    setTimeout(() => {
      this.messages = this.messages.filter((msg: Message) => msg !== message);
    }, 3000);
    return message.text;
  }

}
