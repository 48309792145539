import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../types/message';



@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private messagesSubject = new Subject<Message>();

  constructor() { }

  error (message: string) {
    console.error(message);
    this.messagesSubject.next(new Message("error", message));
  }

  info (message: string) {
    console.info(message);
    this.messagesSubject.next(new Message("info", message));
  }

  success (message: string) {
    console.info(message);
    this.messagesSubject.next(new Message("success", message));
  }

  subscribe(callback: any) {
    this.messagesSubject.subscribe(callback);
  }
}
