import { Component, OnInit } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { SchoolYearService } from '../services/school-year.service';
import { StudentService } from '../services/student.service';
import { SchoolYear } from '../types/schoolyear';
import { DetailledStudent } from '../types/student';

@Component({
  selector: 'app-back-to-school',
  templateUrl: './back-to-school.component.html',
  styleUrls: ['./back-to-school.component.css']
})
export class BackToSchoolComponent implements OnInit {
  private moveBackToSchool = false;
  private moveSortingHat = false;
  private rotateSortinghat = false;
  private moveHouses = false;
  private displayChildren = false;
  private childrenByHouse: any = {
    Gryffondor: [],
    Serpentard: [],
    Poufsouffle: [],
    Serdaigle: []
  };
  private houses: string[] = ["Gryffondor", "Poufsouffle", "Serdaigle", "Serpentard"]

  constructor(private schoolYearService: SchoolYearService, private studentService: StudentService) { }

  ngOnInit(): void {
    this.retrieveChildren().subscribe();
  }

  getHouses(): string[] {
    return this.houses;
  }

  retrieveChildren() {
    return this.schoolYearService.getCurrentSchoolYear().pipe(
      switchMap((schoolYear: SchoolYear) => {
        return this.studentService.getDetailedYearStudents(schoolYear.period, {});
      })
    ).pipe(
      map((students: DetailledStudent[]) => {
        students.forEach(
          (student: DetailledStudent) => {
            this.childrenByHouse[student.house].push(student);
          }
        )
      })
    );
  }

  getChildrenByHouse(house: string) {
    return this.childrenByHouse[house];
  }

  getSortingHatContainerClasses(): string {
    return this.moveSortingHat ? 'transform scale-125 -translate-y-80' : '';
  }

  getSortingHatClasses(): string {
    return this.rotateSortinghat ? ' animate-wiggle' : '';
  }

  getBackToSchoolClasses(): string {
    return this.moveBackToSchool ? 'transform -translate-x-96 -translate-y-24 scale-50' : '';
  }

  getHousesClasses(): string {
    let classes = this.moveSortingHat ? 'flex' : 'hidden';
    classes += this.moveHouses ? ' transform translate-y-44' : '';
    return classes;
  }

  getHouseClasses(): string {
    return this.moveHouses ? 'w-32 h-36' : 'w-0 h-0';
  }

  getChildrenClasses(): string {
    return this.displayChildren ? 'opactity-100' : 'opacity-0';
  }

  displayChildrenList(): void {
    this.displayChildren = true;
    this.rotateSortinghat = false;
  }

  clickSortingHat(): void {
    this.moveBackToSchool = true;
    setTimeout(() => this.moveSortingHat = true, 350)
    setTimeout(() => this.rotateSortinghat = true, 1250)
    setTimeout(() => this.moveHouses = true, 1500)
    setTimeout(() => this.displayChildrenList(), 2500)
  }
}
