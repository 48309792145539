import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SchoolYear } from '../types/schoolyear';
import { CalendarService } from './calendar.service';
import { DirectusService } from './directus.service';
import { MessagesService } from './messages.service';

@Injectable({
  providedIn: 'root',
})
export class SchoolYearService {
  private currentSchoolYear!: SchoolYear;
  constructor(
    private calendarService: CalendarService,
    private directusService: DirectusService,
    private messagesService: MessagesService
  ) {}

  /**
   * Query the API to set the current year back to school ISO date
   */
  getCurrentSchoolYearBackToSchool(): Observable<string> {
    return this.getCurrentSchoolYear().pipe(
      map((currentYear: SchoolYear | null) =>
        currentYear ? currentYear.back_to_school : ''
      )
    );
  }

  getCurrentSchoolYear(): Observable<any> {
    if (this.currentSchoolYear !== undefined) return of(this.currentSchoolYear);
    const today = this.calendarService.getToday();
    return this.directusService
      .readMany('schoolyear', {
        sort: ['-back_to_school'],
        filter: {
          back_to_school: {
            _lte: today.toISODate(),
          },
        },
      })
      .pipe(
        map((items: any) => {
          if (items.data.length === 0) {
            this.messagesService.error('Aucune année scolaire trouvée !');
            return null;
          }
          this.currentSchoolYear = items.data[0];
          return this.currentSchoolYear;
        })
      );
  }
}
