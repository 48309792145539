import { Injectable } from '@angular/core';
import { Directus } from '@directus/sdk';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Child } from '../types/child';
import { SchoolYear } from '../types/schoolyear';
import { Student } from '../types/student';

type SchoolApp = {
  schoolyear: SchoolYear;
  child: Child;
  student: Student;
};

@Injectable({
  providedIn: 'root',
})
export class DirectusService {
  private readonly api: any;

  constructor() {
    this.api = new Directus<SchoolApp>(environment.directusApiUrl);
  }

  makeObservable(promise: Promise<any>): Observable<any> {
    return from(promise);
  }

  readOne(collection: string, id: any) {
    const promise = this.api.items(collection).readOne(id);
    return this.makeObservable(promise);
  }

  readMany(collection: string, options?: any) {
    const promise = this.api.items(collection).readMany(options);
    return this.makeObservable(promise);
  }

  createMany(collection: string, items: any) {
    const promise = this.api.items(collection).createMany(items);
    return this.makeObservable(promise);
  }

  login(credentials: any, options?: any) {
    const promise = this.api.auth.login(credentials, options);
    return this.makeObservable(promise);
  }

  logout() {
    const promise = this.api.auth.logout();
    return this.makeObservable(promise);
  }

  getCurrentUser() {
    const promise = this.api.users.me.read();
    return this.makeObservable(promise);
  }
}
