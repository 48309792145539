import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { DetailledStudent, Student } from '../types/student';
import { ChildrenService } from './children.service';
import { DirectusService } from './directus.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  constructor(
    private childrenService: ChildrenService,
    private directusService: DirectusService
  ) {}

  listStudents(): Observable<Student[]> {
    return this.directusService.readMany('student').pipe(
      map((items: any) => {
        return items.data;
      })
    );
  }

  getStudent(studentId: string): Observable<Student> {
    return this.directusService.readOne('student', studentId).pipe(
      map((items: any) => {
        return items.data;
      })
    );
  }

  _getYearStudents(
    schoolYear: string,
    filters: any,
    detailled: boolean
  ): Observable<any[]> {
    const fields = detailled ? '*.*' : '*';
    return this.directusService
      .readMany('student', {
        filter: {
          ...filters,
          schoolclass: {
            schoolyear: {
              _eq: schoolYear,
            },
          },
        },
        fields: fields,
        limit: -1,
      })
      .pipe(map((items: any) => items.data));
  }

  getYearStudents(schoolYear: string, filters: any): Observable<Student[]> {
    return this._getYearStudents(schoolYear, filters, false);
  }

  getDetailedYearStudents(
    schoolYear: string,
    filters: any
  ): Observable<DetailledStudent[]> {
    return this._getYearStudents(schoolYear, filters, true);
  }
}
