import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneStudentPerHouseComponent } from './one-student-per-house.component';



@NgModule({
  declarations: [OneStudentPerHouseComponent],
  imports: [
    CommonModule,
  ],
  exports: [OneStudentPerHouseComponent]
})
export class OneStudentPerHouseModule { }
