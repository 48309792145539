import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { NavComponent } from './nav/nav.component';
import { AuthGuard } from './guards/auth.guard';
import { LogoutButtonComponent } from './logout-button/logout-button.component';
import { MessagesComponent } from './messages/messages.component';
import { PrefectListComponent } from './home/prefect/prefect-list/prefect-list.component';
import { BackToSchoolComponent } from './back-to-school/back-to-school.component';
import { OneStudentPerHouseModule } from './one-student-per-house/one-student-per-house.module';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'rituals',
    loadChildren: () =>
      import('./rituals/rituals.module').then((m) => m.RitualsModule),
  },
  {
    path: 'games',
    loadChildren: () =>
      import('./games/games.module').then((m) => m.GamesModule),
  },
  {
    path: 'random-pick',
    loadChildren: () =>
      import('./random-pick/random-pick.module').then(
        (m) => m.RandomPickModule
      ),
  },
  {
    path: 'pause',
    loadChildren: () =>
      import('./pause/pause.module').then((m) => m.PauseModule),
  },
  {
    path: 'ent',
    loadChildren: () => import('./ent/ent.module').then((m) => m.EntModule),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    NavComponent,
    LogoutButtonComponent,
    MessagesComponent,
    PrefectListComponent,
    BackToSchoolComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    OneStudentPerHouseModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
