import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Child } from '../types/child';
import { DirectusService } from './directus.service';

const childrenMock = [
  {
    id: 1,
    firstName: 'alice',
    lastName: 'cooper',
    birthDay: '2013-02-25',
  },
  { id: 2, firstName: 'john', lastName: 'mayer', birthDay: '2013-05-14' },
  { id: 3, firstName: 'franck', lastName: 'zappa', birthDay: '2011-08-24' },
];

@Injectable({
  providedIn: 'root',
})
export class ChildrenService {
  constructor(private directusService: DirectusService) {}

  getChild(childId: any): Observable<any> {
    return this.directusService.readOne('child', childId);
  }

  listChildren(): Observable<Child[]> {
    return this.directusService.readMany('child').pipe(
      map((items: any) => {
        return items.data;
      })
    );
  }
}
