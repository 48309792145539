import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DetailedPrefect } from '../types/prefects';
import { SchoolYear } from '../types/schoolyear';
import { DetailledStudent, Student } from '../types/student';
import { DirectusService } from './directus.service';
import { SchoolYearService } from './school-year.service';
import { StudentService } from './student.service';

@Injectable({
  providedIn: 'root'
})
export class PrefectsService {

  constructor(private studentService: StudentService, private schoolYearService: SchoolYearService, private directusService: DirectusService) { }

  getCurrentPrefects(): Observable<DetailedPrefect[]> {
    const today = DateTime.local().setZone('Europe/Paris');
    return this.schoolYearService.getCurrentSchoolYear().pipe(
      switchMap((schoolYear: SchoolYear) => {
        return this.directusService.readMany('prefect', {
          fields: "*.*.*",
          sort: ["-elected_at"],
          filter: {
            student: {
              schoolclass: {
                schoolyear: {
                  period: {
                    _eq: schoolYear.period
                  }
                }
              }
            },
            elected_at: {
              _gte: today.startOf('month').toISODate()
            }
          }
        }).pipe(
          map((items: any) => (items.data.length ? items.data : null)),
          map((prefects: DetailedPrefect[]) => prefects)
        );
      })
    );
  }

  /**
   * Find the minimum mandates count for the house and pick a random Student
   * @param house One of Gryffondor, Poufsouffle, Serdaigle or Serpentard
   * @param studentsByHouse Object with mandates count as keys and corresponding students as values
   * @returns The next Student to be prefect
   */
  selectHousePrefect(house: string, studentsByHouse: any): DetailledStudent {
    const house_min = Math.min(...Object.keys(studentsByHouse).map((x: string) => parseInt(x)));
    return studentsByHouse[house_min][Math.floor(Math.random() * studentsByHouse[house_min].length)];
  }

  findNewPrefects(): Observable<DetailledStudent[]> {
    // retrieve all students with prefect mandates
    const studentsByHouse: any = {
      Gryffondor: {},
      Poufsouffle: {},
      Serdaigle: {},
      Serpentard: {}
    };
    return this.schoolYearService.getCurrentSchoolYear().pipe(
      switchMap((schoolYear: SchoolYear) => {
        return this.studentService.getDetailedYearStudents(schoolYear.period, {}).pipe(
          map((students: DetailledStudent[]) => {
            for (const student of students) {
              if (studentsByHouse[student.house][student.prefect_mandates.length])
                studentsByHouse[student.house][student.prefect_mandates.length].push(student);
              else
                studentsByHouse[student.house][student.prefect_mandates.length] = [student];
            }

            const gryf_prefect = this.selectHousePrefect("Gryffondor", studentsByHouse.Gryffondor);
            const serd_prefect = this.selectHousePrefect("Serdaigle", studentsByHouse.Serdaigle);
            const pouf_prefect = this.selectHousePrefect("Poufsouffle", studentsByHouse.Poufsouffle);
            const serp_prefect = this.selectHousePrefect("Serpentard", studentsByHouse.Serpentard);
            return [gryf_prefect, pouf_prefect, serd_prefect, serp_prefect];
          })
        )
      })
    )
  }

  electNewPrefects(): Observable<any> {
    return this.findNewPrefects().pipe(
      switchMap((students: DetailledStudent[]) => {
        const prefects: DetailedPrefect[] = [];
        for (const student of students) {
          prefects.push({
            id: '', student, elected_at: DateTime.local().setZone('Europe/Paris').toISODate()
          })
        }
        return this.directusService.createMany('prefect', prefects);
      })
    )
  }
}
