<div *ngIf="getPrefects() as prefects; else electPrefects" class="w-full flex flex-row justify-center  space-x-28 mb-24">
    <app-one-student-per-house *ngFor="let prefect of prefects" [student]=prefect.student></app-one-student-per-house>
</div>

<ng-template #electPrefects>
<img class="cursor-pointer duration-500 transition-transform ease-in origin-center w-60 mx-auto"
      [ngClass]="getSortingHatClasses()"
      (click)="clickSortingHat()"
      src="assets/img/hp-sorting-hat-square.png"
      alt="Sorting Hat" />
</ng-template>
