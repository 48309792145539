<!-- This example requires Tailwind CSS v2.0+ -->
<nav class="bg-gray-800">
  <div class="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button (click)="onMobileMenuClick()" type="button"
          class="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          aria-controls="mobile-menu" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.

            Heroicon name: outline/menu

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
          <!--
            Icon when menu is open.

            Heroicon name: outline/x

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
          <div class="flex items-center flex-shrink-0">
            <span class="text-lg font-bold text-white">{{ getUserDisplayName() }}</span>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <a *ngFor="let navItem of navItems" routerLink="{{ navItem.path }}" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" class="text-sm nav-link" aria-current="page">{{
                navItem.display
                }}</a>
            </div>
          </div>
        </div>
        <app-logout-button class="hidden sm:block"></app-logout-button>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:hidden" [ngClass]="{'hidden': !showMobileMenu()}">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a *ngFor="let navItem of navItems" routerLink="{{ navItem.path }}" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}" class="block text-base nav-link" aria-current="page">{{
        navItem.display }}</a>
      <hr class="border-gray-900">
      <app-logout-button class=""></app-logout-button>
    </div>
  </div>
</nav>
