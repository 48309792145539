<div class="mt-28 w-full text-center duration-500 font-cursive text-4xl sm:text-6xl md:text-7xl xl:text-8xl font-semibold mb-6 transition-transform ease-in"
     [ngClass]="getBackToSchoolClasses()">
  C'est la rentrée
</div>

<div class="cursor-pointer duration-500 transition-transform ease-in origin-center w-96 my-2 mx-auto"
     [ngClass]="getSortingHatContainerClasses()">
  <img id="sortingHat" class="duration-500 transition-transform ease-in origin-center w-full"
      [ngClass]="getSortingHatClasses()"
      (click)="clickSortingHat()"
      src="assets/img/hp-sorting-hat-square.png"
      alt="Sorting Hat" />
</div>

<div class="w-full flex-row justify-center absolute top-48 space-x-24 duration-500 transition-transform ease-in origin-center"
   [ngClass]="getHousesClasses()">

   <div *ngFor="let house of getHouses()">
      <img class="mx-auto duration-500 transition-width transition-height" [ngClass]="getHouseClasses()" src="assets/img/hp-house-{{ house }}.png" alt="hp-house-{{ house }}">
      <div class="mx-auto transition-opacity mt-12" [ngClass]="getChildrenClasses()">
         <p class="text-2xl text-center mt-1" *ngFor="let student of getChildrenByHouse(house)">
            {{ student.child.firstname }} {{ student.child.lastname }}
         </p>
      </div>
   </div>

</div>
