<div class="w-full h-screen -mt-16 pt-16" *ngIf="getIsLoaded()">
  <ng-template #backToSchool>
    <app-back-to-school></app-back-to-school>
  </ng-template>

  <div class="flex flex-col h-full justify-center space-y-16" *ngIf="!(getIsBackToSchool() | async); else backToSchool">
    <app-prefect-list></app-prefect-list>
    <div>
      <div id="dateFrench"
        class="w-full text-center font-cursive text-4xl sm:text-6xl md:text-7xl xl:text-8xl font-semibold mb-6">
        <div class="inline-block">
          {{ getTodayInFrench() | titlecase }}
          <div class="w-fill border-b-2 border-red-500 -mt-2"></div>
        </div>
      </div>
      <div id="dateEnglish"
        class="w-full text-center font-cursive text-2xl sm:text-4xl md:text-5xl xl:text-6xl text-gray-600 font-semibold">
        {{ getTodayInEnglish() | titlecase }}</div>
      </div>
    </div>
</div>
