import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SchoolYearService } from '../services/school-year.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private isBackToSchool!: Boolean;
  private isLoaded = false;
  constructor(private schoolYearService: SchoolYearService) { }

  getToday(): DateTime {
    return DateTime.local().setZone('Europe/Paris');
  }

  ngOnInit(): void {
    this.schoolYearService.getCurrentSchoolYearBackToSchool().pipe(
      map((backToSchool: String) => {
        this.isBackToSchool = backToSchool === this.getToday().toISODate();
        this.isLoaded = true;
      })
    ).subscribe();
  }

  getTodayInFrench(): string {
    const today = this.getToday();
    return today.setLocale('fr').toLocaleString(DateTime.DATE_HUGE);
  }

  getTodayInEnglish(): string {
    const today = this.getToday();
    return today.setLocale('en').toLocaleString(DateTime.DATE_HUGE);
  }

  getIsBackToSchool(): Observable<Boolean> {
    return of(this.isBackToSchool);
  }

  getIsLoaded(): Boolean {
    return this.isLoaded;
  }
}
