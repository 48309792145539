import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent implements OnInit {
  private mobileMenuIsOpen = false;

  navItems = [
    { display: 'Accueil', path: '' },
    { display: 'Rituels', path: 'rituals' },
    { display: 'Jeux', path: 'games' },
    { display: 'Tirage au sort', path: 'random-pick' },
    { display: 'Pause', path: 'pause' },
  ];

  constructor(private authService: AuthService) { }

  getUserDisplayName(): string {
    const user = this.authService.getLoggedInUser();
    return `${user.first_name} ${user.last_name}`;
  }

  ngOnInit(): void { }

  showMobileMenu(): boolean {
    return this.mobileMenuIsOpen;
  }

  onMobileMenuClick(): void {
    this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
  }
}
