import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { PrefectsService } from 'src/app/services/prefects.service';
import { DetailedPrefect } from 'src/app/types/prefects';

@Component({
  selector: 'app-prefect-list',
  templateUrl: './prefect-list.component.html',
  styleUrls: ['./prefect-list.component.css'],
})
export class PrefectListComponent implements OnInit {
  private prefects!: DetailedPrefect[];
  private sortingHatClicked = false;
  constructor(private prefectsService: PrefectsService) { }

  ngOnInit(): void {
    this.prefectsService
      .getCurrentPrefects()
      .subscribe((prefects) => (this.prefects = prefects));
  }

  getPrefects(): DetailedPrefect[] {
    return this.prefects;
  }

  clickSortingHat() {
    this.sortingHatClicked = true;
    setTimeout(() => {
      this.sortingHatClicked = false;
    }, 1100);
    this.prefectsService.electNewPrefects().pipe(
      map((prefects: DetailedPrefect[]) => this.prefects = prefects)
    ).subscribe({
      next: () => {
        this.prefectsService
          .getCurrentPrefects()
          .subscribe((prefects) => (this.prefects = prefects));
      }
    });
  }

  getSortingHatClasses() {
    return this.sortingHatClicked ? 'animate-wiggle' : '';
  }
}
