import { Component, Input, OnInit } from '@angular/core';
import { DetailledStudent } from '../types/student';

@Component({
  selector: 'app-one-student-per-house',
  templateUrl: './one-student-per-house.component.html',
  styleUrls: ['./one-student-per-house.component.css']
})
export class OneStudentPerHouseComponent implements OnInit {

  @Input() student!: DetailledStudent;

  constructor() { }

  ngOnInit(): void {
  }

}
