import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DirectusService } from './directus.service';
import { MessagesService } from './messages.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private loggedInUser!: any;

  constructor(private directusService: DirectusService, private messagesService: MessagesService) {
    this.isLoggedIn().subscribe();
  }

  login(
    email: string,
    password: string,
    otp: string | undefined
  ): Observable<void> {
    return this.directusService
      .login(
        { email, password, otp },
        {
          refresh: {
            auto: true,
          },
        }
      )
      .pipe(
        map(() => this.isLoggedInSubject.next(true)),
        catchError(err => this.handleError(err))
      );
  }

  logout(): Observable<void> {
    return this.directusService
      .logout()
      .pipe(map(() => this.isLoggedInSubject.next(false)));
  }

  getLoggedInUser(): any {
    return this.loggedInUser;
  }

  isLoggedIn(): Observable<boolean> {
    return this.directusService.getCurrentUser().pipe(
      map((user: any): boolean => {
        this.loggedInUser = user;
        this.isLoggedInSubject.next(true);
        return true;
      }),
      catchError((): Observable<boolean> => {
        this.isLoggedInSubject.next(false);
        return of(false);
      })
    );
  }

  async handleError(error: any) {
    this.messagesService.error(error.response.errors[0].message);
  }
}
