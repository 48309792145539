import { Injectable } from '@angular/core';
import { DateTime, Info } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private readonly today: DateTime;

  constructor() {
    this.today = DateTime.now().setZone("Europe/Paris");
  }

  getToday(): DateTime {
    return this.today.startOf('day');
  }

  getCalendar(): (DateTime | null)[][] {
    return this.getCalendarFromObject({
      year: this.getToday().year,
      month: this.getToday().month,
    });
  }

  getCalendarFromObject(monthObj: {
    year: number;
    month: number;
  }): (null | DateTime)[][] {
    const monthDate = DateTime.fromObject(monthObj).setZone("Europe/Paris").startOf('day');
    const daysInMonth = monthDate.daysInMonth;
    const monthStartWeekDay = monthDate.startOf('month').weekday;
    const monthEndWeekDay = monthDate.endOf('month').weekday;
    const calendarDays = [
      ...[...Array(monthStartWeekDay - 1).keys()].map(() => null), // start of month
      ...[...Array(daysInMonth).keys()].map((x) => monthDate.plus({ days: x })), // days of month
      ...[...Array(7 - monthEndWeekDay).keys()].map(() => null), // end of month
    ];
    return [
      calendarDays.slice(0, 7),
      calendarDays.slice(7, 14),
      calendarDays.slice(14, 21),
      calendarDays.slice(21, 28),
      calendarDays.slice(28, 35),
      calendarDays.slice(35),
    ];
  }

  getTodayMonthName(): string {
    const monthNumber = this.getToday().month;
    return Info.months('long', { locale: 'fr-FR' })[monthNumber - 1];
  }

  getTodayDay(): number {
    return this.getToday().day;
  }

  getWeekDays(): string[] {
    return Info.weekdays('short', { locale: 'fr-FR' });
  }
}
