<button class="flex justify-center px-2 py-2 text-white transition rounded right-4 group ease hover:bg-gray-900"
  (click)="onLogout()" id="logoutButton">
  <span class="flex items-center">
    <svg class="w-5 h-5 text-gray-300 group-hover:text-blue-100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
      fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd"
        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
        clip-rule="evenodd" />
    </svg>
    <span class="text-base sm:hidden nav-link">Se déconnecter</span>
  </span>
</button>
